import {useEffect, useRef, useState} from "react";
import Item from "./Item";

export default function Select({label, placeholder, options, value, onChange, classNameWrapper = "", removable = false, disabled = false, searchable = false, searchFixedTop = false, onBlur}) {

    const [isOpen, setIsOpen] = useState(false);
    const selectRef = useRef(null)
    const [optionList, setOptionList] = useState(options || []);
    const [filterText, setFilterText] = useState("")

    useEffect(() => {
        document.addEventListener('mousedown', handleSelectIsOpen);

        return () => {
            document.removeEventListener('mousedown', handleSelectIsOpen);
        };
    }, [])

    const handleSelectIsOpen = (e) => {
        if (selectRef?.current && !selectRef.current.contains(e.target)) {
            setIsOpen(false)
        }
    }

    useEffect(() => {
        setIsOpen(false)
    }, [value])

    const handleRemoveValue = (e) => {
        e.stopPropagation()
        onChange && onChange(null);
    }

    const handleClick = () => {
        setIsOpen(prevState => !prevState);
        onBlur && onBlur();
    }

    useEffect(() => {
        setOptionList(options);
    },[options])

    useEffect(() => {
        if (filterText !== "") {
            const resultSearch = options.filter(option => option.label.toLowerCase().search(filterText.toLowerCase()) > -1);
            setOptionList(resultSearch)
        } else {
            setOptionList(options)
        }
    },[filterText])

    return (
        <div className={`form-select-wrapper position-relative ${classNameWrapper}`} ref={selectRef}>
            {
                label ? <label className="form-label">{label}</label> : null
            }
            <div className={`form-select position-relative${isOpen ? " list-open" : ""}${disabled ? " disabled" : ""}`} onClick={handleClick}>
                <span>{value ? value?.label : placeholder}</span>
                {
                    removable ? (<i className="bx bx-x cursor-pointer btn-select-remove" onClick={handleRemoveValue}/>) : null
                }
            </div>
            <ul className={`form-select-options pe-0 ${isOpen ? "is-open" : ""}`}>
                {
                    searchable ? (<li className={`form-select-option bg-white p-1${searchFixedTop ? " position-sticky top-0" : ""}`}>
                        <input type="text" className="form-control" value={filterText} onChange={e => setFilterText(e.target.value)} placeholder="جستجو..." style={{height: "40px"}}/>
                    </li>) : null
                }
                {
                    optionList && optionList.map(item => {
                        return (
                            <Item item={item} key={item.value} onClick={onChange}/>
                        )
                    })
                }
            </ul>
        </div>
    )
}