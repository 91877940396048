import {Link} from "react-router-dom";
import {useState} from "react";

export default function InputPassword({label, textLink, link, className, dir, name, placeholder, formik, ref, autoComplete = false}) {

    const [isShowPassword, setIsShowPassword] = useState(false);

    return (
        <div className="mb-3 form-password-toggle">
            {
                label && <label className="form-label">{label}</label>
            }
            <div className="input-group input-group-merge">
                <input type={isShowPassword ? "text" : "password"} className={`form-control ${className} ${formik.errors[name] && formik.touched[name] ? "is-invalid" : ""}`}
                       dir={dir} name={name} placeholder={placeholder}
                       aria-describedby="password" value={formik.values[name]} onBlur={formik.handleBlur} autoComplete="off" onChange={formik.handleChange} ref={ref ? ref : null}/>
                {
                    formik.touched[name] && formik.errors[name] ? (<div className="invalid-feedback">{formik.errors[name]}</div>) : null
                }
                <span className="input-group-text cursor-pointer" onClick={() => setIsShowPassword(prev => !prev)}><i className={`bx bx-${isShowPassword ? "show" : "hide"}`}></i></span>
            </div>
        </div>
    )
}