import MenuItem from "../Menu/MenuItem";
import {Link, useLocation} from "react-router-dom";
import {useIsOpenSidebar, useIsOpenSidebarAction} from "../../context/toggleSidebarContext";
import {useEffect, useState} from "react";
import data from "../../mockData/menu.json";
import Restricted from "../../context/Restricted";

export default function Sidebar() {

    const [selectedParent, setSelectedParent] = useState(null);
    const {pages} = data;
    const setIsOpenSidebar = useIsOpenSidebarAction();
    const isOpenSidebar = useIsOpenSidebar();
    const route = useLocation()

    const handleHoverSidebar = () => {
        if (isOpenSidebar.collapsed) {
            setIsOpenSidebar({...isOpenSidebar, hovered: true})
        }
    }

    useEffect(() => {
        setSelectedParent(route.pathname.split("/")[1]);
    },[route.pathname])

    const handleBlurSidebar = () => {
        if (isOpenSidebar.collapsed) {
            setIsOpenSidebar({...isOpenSidebar, hovered: false})
        }
    }

    useEffect(() => {
        if (isOpenSidebar.collapsed) {
            if (isOpenSidebar.expanded) {
                setIsOpenSidebar({...isOpenSidebar, expanded: false})
            }
        }
    },[route.pathname])

    return (
        <aside id="layout-menu" className="layout-menu menu-vertical menu bg-menu-theme"
               onMouseEnter={handleHoverSidebar} onMouseLeave={handleBlurSidebar}>
            <div className="app-brand demo">
                <Link to="/" className={`app-brand-link mx-auto ${isOpenSidebar.collapsed && !isOpenSidebar.expanded && !isOpenSidebar.hovered ? "ms-n4" : ""}`}>
                    <span className="app-brand-logo demo">
                         <img src="/logo.png" alt="logo"/>
                      </span>
                    {/*<span className="app-brand-text demo menu-text fw-bold ms-2 display-8">آجیل برادران حسینی</span>*/}
                </Link>

                <button className="layout-menu-toggle bg-transparent border-0 menu-link text-large ms-auto">
                    <i className="bx menu-toggle-icon d-none d-xl-block fs-4 align-middle"
    onClick={() => setIsOpenSidebar({...isOpenSidebar, collapsed: !isOpenSidebar.collapsed})}/>
                    <i className="bx bx-x d-block d-xl-none bx-sm align-middle"
    onClick={() => setIsOpenSidebar({...isOpenSidebar, expanded: false})}/>
                </button>
            </div>

            <div className="menu-divider mt-0"/>

            <div className="menu-inner-shadow"/>

            <ul className="menu-inner py-1">
                    {
                        pages && pages.length > 0 && pages.map(item => {
                            return (
                                <Restricted to={item?.permission || ""} key={item.id}>
                                    <MenuItem item={item} onClick={setSelectedParent} selectedParent={selectedParent}/>
                                </Restricted>
                            )
                        })
                    }
            </ul>
        </aside>
    )
}