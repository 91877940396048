import ProductDetailRepeater from "../components/Product/ProductDetailRepeater";
import {useState} from "react";

export default function Sample() {

    const [selectedWeight, setSelectedWeight] = useState(null);
    const [formRepeat, setFormRepeat] = useState([
        {id: 1, selectedWeight: null, price: '', accountingCode: '', discountType: '', discount: ''}
    ])
    const weights = [
        {value: 1, label: '100 گرمی'},
        {value: 2, label: '200 گرمی'},
        {value: 3, label: '500 گرمی'},
        {value: 4, label: '1 کیلویی'},
        {value: 5, label: '2 کیلویی'},
    ]

    const handleAddForm = () => {
        const tempFormRepeat = [...formRepeat];
        tempFormRepeat.push({id: Math.floor(Math.random() * 1000 + 1), selectedWeight: null, price: '', accountingCode: '', discountType: '', discount: ''});
        setFormRepeat(tempFormRepeat);
    }

    const handleDelete = id => {
        let tempFormRepeat = [...formRepeat];
        const newFormRepeat = tempFormRepeat.filter(item => item.id !== id);
        setFormRepeat(newFormRepeat);
    }

    return (
        <div className="container-fluid h-100 w-100 bg-white">
            <div className="row">
                <div className="col-12">
                    <ProductDetailRepeater formRepeat={formRepeat} weights={weights} selectedWeight={selectedWeight} setSelectedWeight={setSelectedWeight} onDelete={handleDelete}/>
                </div>
            </div>
            <div className="row">
                <div className="col-12 mb-4">
                    <button type="button" className="btn btn-primary" onClick={handleAddForm}>افزودن مورد</button>
                </div>
            </div>
        </div>
    )
}