import {useEffect, useRef, useState} from "react";
import {useIsOpenSidebar, useIsOpenSidebarAction} from "../../context/toggleSidebarContext";
import {useAuth} from "../../context/AuthContext";
import {logout} from "../../services";
import {useNavigate} from "react-router-dom";
import Loading from "../Loading";
export default function Header() {

    const [isShowDropdownUser, setIsShowDropdownUser] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);
    const dropdownUserRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const setIsOpenSidebar = useIsOpenSidebarAction()
    const isOpenSidebar = useIsOpenSidebar();

    const auth = useAuth();

    useEffect(() => {
        document.addEventListener('mousedown', handleDropdownUser);
        window.addEventListener("scroll", handleScroll);

        return () => {
            document.removeEventListener('mousedown', handleDropdownUser);
            window.removeEventListener("scroll", handleScroll);
        };
    }, [])

    const handleScroll = () => {
        if (document.body.scrollTop > 10 || document.documentElement.scrollTop > 10) {
            setIsScrolled(true);
        } else {
            setIsScrolled(false)
        }
    }

    const handleDropdownUser = (e) => {
        if (dropdownUserRef?.current && !dropdownUserRef.current.contains(e.target)) {
            setIsShowDropdownUser(false)
        }
    }

    const handleLogout = async () => {
        setIsLoading(true);
        try {
            const {isSuccess} = await logout();
            if (isSuccess) {
                navigate('/login')
            }
            setIsLoading(false)
        } catch (ex) {
            console.log(ex);
            setIsLoading(false);
        }
    }

    return (
        <>
            <nav className={`layout-navbar navbar navbar-expand-xl align-items-center bg-navbar-theme ${isScrolled ? "navbar-elevated" : ""}`} id="layout-navbar">
                <div className="container-fluid">
                    <div className="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0 d-xl-none"
                         onClick={() => setIsOpenSidebar({...isOpenSidebar, expanded: true})}>
                        <button className="nav-item nav-link px-0 me-xl-4 bg-transparent border-0">
                            <i className="bx bx-menu bx-sm"/>
                        </button>
                    </div>

                    <div className="navbar-nav-right d-flex align-items-center" id="navbar-collapse">
                        <ul className="navbar-nav flex-row align-items-center ms-auto">
                            <li className="nav-item me-2 me-xl-0">
                                <button className="nav-link style-switcher-toggle hide-arrow border-0 bg-transparent">
                                    <i className="bx bx-sm"/>
                                </button>
                            </li>
                            <li className="nav-item navbar-dropdown dropdown-user dropdown"
                                onClick={() => setIsShowDropdownUser(prevState => !prevState)} ref={dropdownUserRef}>
                                <button className={`nav-link dropdown-toggle hide-arrow bg-transparent border-0 ${isShowDropdownUser ? "show" : ""}`}
                                   data-bs-toggle="dropdown">
                                    <div className="avatar avatar-online">
                                        <img src="/avatars/1.png" alt="avatar" className="rounded-circle"/>
                                    </div>
                                </button>
                                <ul className={`dropdown-menu dropdown-menu-end ${isShowDropdownUser ? "show" : ""}`}
                                    data-bs-popper="none">
                                    <li>
                                        <div className="dropdown-item">
                                            <div className="d-flex align-items-center">
                                                <div className="flex-shrink-0 me-3">
                                                    <div className="avatar avatar-online mt-1">
                                                        <img src="/avatars/1.png" alt="avatar"
                                                             className="rounded-circle"/>
                                                    </div>
                                                </div>
                                                <div className="flex-grow-1">
                                                    <span className="fw-semibold d-block">{`${auth?.userInfo?.firstName} ${auth?.userInfo?.lastName}`}</span>
                                                    {/*<small>مدیر</small>*/}
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="dropdown-divider"/>
                                    </li>
                                    <li>
                                        <button className="dropdown-item" onClick={handleLogout}>
                                            <i className="bx bx-power-off me-2"/>
                                            <span className="align-middle">خروج</span>
                                        </button>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            {
                isLoading ? (<Loading />) : null
            }
        </>
    )
}