import {useEffect} from "react";
import {Outlet, Route, Routes, Navigate, useNavigate, useLocation} from "react-router-dom";
import {useIsOpenSidebar, useIsOpenSidebarAction} from "../context/toggleSidebarContext";
import {useAuth, useAuthAction} from "../context/AuthContext";
import {useSweetAlert} from "../context/sweetAlertContext";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Sidebar from "../components/Sidebar";
import {panelRoutes} from "../routes";
import {me} from "../services";
import Dashboard from "../pages/Dashboard";
import PermissionProvider from "../context/PermissionContext";
import Restricted from "../context/Restricted";
import Loading from "../components/Loading";

export default function AdminLayout({children}) {

    const isShowSidebar = useIsOpenSidebar();
    const setIsOpenSidebar = useIsOpenSidebarAction();
    const navigate = useNavigate();
    const auth = useAuth();
    const authAction = useAuthAction();
    const sweetAlert = useSweetAlert();

    const token = localStorage.getItem("token");

    const {pathname, search} = useLocation();

    useEffect(() => {
        const currentTitle = panelRoutes.find(item => item.path === pathname)
        if (currentTitle && currentTitle.title) {
            document.title = currentTitle.title
        }

        if (isShowSidebar.expanded) {
            setIsOpenSidebar({...isShowSidebar, expanded: false})
        }
    }, [pathname, search]);

    useEffect(() => {
        window.addEventListener("offline", () => {
            sweetAlert({
                show: true,
                title: "قطع ارتباط",
                icon: "error",
                text: "اتصال شما با اینترنت قطع شد.",
                confirmButtonText: "بارگذاری مجدد",
                onConfirm: () => window.location.reload()
            })
        });
    }, [])

    useEffect(() => {
        const html = document.querySelector('html');
        if (isShowSidebar.expanded) {
            html.className = 'light-style layout-navbar-fixed layout-menu-fixed layout-menu-expanded';
        } else if (isShowSidebar.collapsed && !isShowSidebar.hovered) {
            html.className = 'light-style layout-navbar-fixed layout-menu-fixed layout-menu-collapsed'
        } else if (isShowSidebar.collapsed && isShowSidebar.hovered) {
            html.className = 'light-style layout-navbar-fixed layout-menu-fixed layout-menu-collapsed layout-menu-hover'
        } else {
            html.className = 'light-style layout-navbar-fixed layout-menu-fixed';
        }
    }, [isShowSidebar])

    const loadUser = async () => {
        try {
            const {userInfo, userPermission} = await me();
            if (userInfo?.isSuccess) {
                authAction({userInfo: userInfo?.data, userPermission});
            }
        } catch (ex) {
            navigate('/login')
        }
    }

    useEffect(() => {
        if (!token) navigate('/login')
        if (token && !auth) {
            loadUser();
        }
    }, [])

    const closeSidebar = () => {
        setIsOpenSidebar({...isShowSidebar, expanded: false})
    }

    if (!auth) return <Loading />

    return (
        <PermissionProvider permissions={auth?.userPermission}>
            <div className="layout-wrapper layout-content-navbar">
                <div className="layout-container">

                    {
                        auth?.userInfo?.template !== "NotMenu" ? <Sidebar/> : null
                    }

                    <div className={`layout-page ${auth?.userInfo?.template === "NotMenu" ? "pt-custom-0" : ""}`}>
                        {
                            auth?.userInfo?.template !== "NotMenu" ? <Header/> : null
                        }

                        <div className="content-wrapper">
                            <div className="container-xxl flex-grow-1 container-p-y">
                                <Routes>
                                    {
                                        panelRoutes.map(route => {
                                            return (<Route key={route.id} path={route.path} element={
                                                token ? <Restricted to={route.permission} replace={true}>
                                                        <route.component/>
                                                    </Restricted> :
                                                    <Navigate to="/login" replace={true}/>
                                            }/>)
                                        })
                                    }
                                    <Route path="/*" element={<Dashboard/>}/>
                                </Routes>
                                <Outlet/>
                                {children}
                            </div>
                            <Footer/>
                            <div className="content-backdrop fade"/>
                        </div>

                    </div>
                </div>

                <div className="layout-overlay layout-menu-toggle" onClick={closeSidebar}/>

                {/*Drag Target Area To SlideIn Menu On Small Screens*/}
                <div className="drag-target"/>
            </div>
        </PermissionProvider>
    )
}