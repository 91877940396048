import Select from "../Select";
import Input from "../Input";
import {useEffect, useRef, useState} from "react";
import Toggle from "../Toggle";

const discountTypes = [
    {value: 1, label: 'بدون تخفیف'},
    {value: 2, label: 'درصدی'},
    {value: 3, label: 'مبلغی'},
]

export default function ProductDetailRepeater({data, formik, onDelete, index, units}) {

    const [selectedDiscountType, setSelectedDiscountType] = useState(discountTypes[0]);
    const [selectedUnitId, setSelectedUnitId] = useState(null);
    const [isOpen, setIsOpen] = useState(false);

    const dropdownRef = useRef(null);

    useEffect(() => {
        if (data && data.productPrices[index]) {
            const tempSelectedDiscountType = discountTypes.find(item => item.value === data.productPrices[index].discountType);

            if (tempSelectedDiscountType) {
                setSelectedDiscountType(tempSelectedDiscountType)
            }
        }
        if (data && data.productPrices[index] && units.length > 0) {
            const tempSelectedUnitId = units.find(item => item.value === data.productPrices[index].unitId);
            if (tempSelectedUnitId) {
                setSelectedUnitId(tempSelectedUnitId)
            }
        }
    }, [data, units])

    useEffect(() => {
        if (selectedUnitId) formik.setFieldValue(`productPrices[${index}].unitId`, selectedUnitId);
        if (selectedDiscountType) formik.setFieldValue(`productPrices[${index}].discountType`, selectedDiscountType);
    }, [selectedUnitId, selectedDiscountType])

    useEffect(() => {
        document.addEventListener('click', handleDropdownIsOpen);

        return () => {
            document.removeEventListener('click', handleDropdownIsOpen);
        };
    }, [])

    const handleDropdownIsOpen = (e) => {
        if (dropdownRef?.current && !dropdownRef.current.contains(e.target)) {
            setIsOpen(false)
        }
    }

    useEffect(() => {
        if (formik.values.productPrices[index].basePrice > 0 && formik.values.productPrices[index].unitId && formik.values.productPrices[index]?.unitId?.value) {
            formik.setFieldValue(`productPrices[${index}].price`, formik.values.productPrices[index].basePrice * formik.values.productPrices[index].unitId.unit)
        }

    },[formik.values.productPrices[index].basePrice, formik.values.productPrices[index].unitId])

    return (
        <div className="repeater-wrapper pt-0 pt-md-4">
            <div className="d-flex border rounded position-relative pe-0">
                <div className="row w-100 m-0 p-3">
                    <div className="col-md-3 col-12 mb-md-0 mb-3 ps-md-0">
                        <Input placeholder="قیمت پایه" label="قیمت پایه"
                               value={formik.values.productPrices[index]?.basePrice.toLocaleString("IR-fa")} type="text"
                               formik={formik}
                               name={`productPrices[${index}].basePrice`}/>
                    </div>
                    <div className="col-md-3 col-12 mb-md-0 mb-3">
                        <Select
                            onChange={value => formik.setFieldValue(`productPrices[${index}].unitId`, value)}
                            options={units}
                            searchable={false}
                            value={formik.values.productPrices[index].unitId}
                            label="واحد"
                            placeholder="انتخاب کنید.."
                        />
                    </div>
                    <div className="col-md-2 col-12 mb-md-0 mb-3">
                        <Input placeholder="قیمت" label="قیمت"
                               value={formik.values.productPrices[index]?.price.toLocaleString("IR-fa")} type="text"
                               formik={formik}
                               name={`productPrices[${index}].price`}
                               readOnly={true}
                               disabled={true}
                        />
                    </div>
                    <div className="col-md-2 col-12 mb-md-0 mb-3">
                        <Input placeholder="کد حسابداری محصول" label="کد حسابداری محصول"
                               value={formik.values.productPrices[index].accountingCode} type="text"
                               formik={formik} name={`productPrices[${index}].accountingCode`}/>
                    </div>
                    <div className="col-md-2 col-12 mb-md-0 mb-3 ps-md-0 d-flex align-items-center justify-content-between">
                        <Toggle label="فعال" formik={formik} value={formik.values.productPrices[index]?.isActive} checked={formik.values.productPrices[index]?.isActive} type="checkbox" name={`productPrices[${index}].isActive`} onChange={e => formik.setFieldValue(`productPrices[${index}].isActive`, e.target.checked)} />
                        <Toggle label="موجود" formik={formik} value={formik.values.productPrices[index]?.isAvailable} checked={formik.values.productPrices[index]?.isAvailable} type="checkbox" name={`productPrices[${index}].isAvailable`}  onChange={e => formik.setFieldValue(`productPrices[${index}].isAvailable`, e.target.checked)} />
                    </div>
                </div>
                <div
                    className="d-flex flex-column align-items-center justify-content-between border-start p-2">
                    <i className="bx bx-x fs-4 text-muted cursor-pointer"
                       onClick={() => onDelete()}/>
                    <div className="dropdown" ref={dropdownRef}>
                        <i className={`bx bx-cog bx-xs text-muted cursor-pointer more-options-dropdown ${isOpen ? "show" : ""}`}
                           role="button"
                           id="dropdownMenuButton" data-bs-toggle="dropdown" data-bs-auto-close="outside"
                           aria-expanded="false" onClick={() => setIsOpen(prevState => !prevState)}/>
                        <div className={`dropdown-menu dropdown-menu-end w-px-300 p-3 ${isOpen ? "show" : ""}`}
                             aria-labelledby="dropdownMenuButton" style={isOpen ? {
                            position: "absolute",
                            inset: "0px auto auto 0px",
                            margin: "0px",
                            transform: "translate(0px, 21px)"
                        } : null}>
                            <div className="row g-3">
                                <div className="col-12">
                                    <Select
                                        onChange={value => formik.setFieldValue(`productPrices[${index}].discountType`, value)}
                                        options={discountTypes}
                                        searchable={false}
                                        value={formik.values.productPrices[index]?.discountType}
                                        label="نوع تخفیف"
                                        placeholder="انتخاب کنید.."
                                    />
                                </div>
                                <div className="col-md-12">
                                    <Input placeholder="مقدار تخفیف" label="مقدار تخفیف"
                                           value={formik.values.productPrices[index]?.discount.toLocaleString("IR-fa")} type="text"
                                           formik={formik} name={`productPrices[${index}].discount`}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}