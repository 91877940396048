export default function Print() {
    const price = 174650;
    return (
        <div className="print-wrapper">
            <div className="print-box">
                <p className="print-header">شعبه اینترنتی فروشگاه برادران حسینی</p>
                <div className="px-2">
                    <div className="text-center">
                        <p className="text-uppercase text-dark">HOSSEINIBROTHERS.COM</p>
                        <img src="/logo.png" alt="logo" className="w-50"/>
                    </div>
                    <div className="print-support">
                        <div className="header-support-print">
                            <p>تلفن پشتیبانی مشتریان:</p>
                            <p>09159881606 - 09374444897</p>
                        </div>
                        <div>
                            ساعات پشتیبانی همه روزه از ساعت 9 الی 21
                            <p className="text-center">hosseinibrothers<i className="bx bxl-instagram" /></p>
                        </div>
                        <div className="footer-support-print">
                            <p>09159881606<i className="bx bxl-whatsapp" /></p>
                        </div>
                    </div>
                    <div className="print-product-list mt-2">
                        <div className="print-product-item">
                            <div className="print-product-item-header">
                                <span>نام محصول:</span>
                            </div>
                            <div className="print-product-item-detail">
                                <span>فی</span>
                                <span>مبلغ</span>
                                <span>تعداد</span>
                            </div>
                        </div>
                        <div className="print-product-item">
                            <div className="print-product-item-header">
                                <span>آجیل مغز سنتی - وزن: 250 گرم</span>
                            </div>
                            <div className="print-product-item-detail">
                                <span>{price.toLocaleString("fa-IR")}</span>
                                <span>{price.toLocaleString("fa-IR")}</span>
                                <span>1</span>
                            </div>
                        </div>
                        <div className="print-product-item">
                            <div className="print-product-item-header">
                                <span>آفتابگردان دورسفید گلپر - وزن: 500 گرم</span>
                            </div>
                            <div className="print-product-item-detail">
                                <span>{price.toLocaleString("fa-IR")}</span>
                                <span>{price.toLocaleString("fa-IR")}</span>
                                <span>1</span>
                            </div>
                        </div>
                        <div className="print-product-item">
                            <div className="print-product-item-header">
                                <span>سوهان لقمه ساعدی نیا</span>
                            </div>
                            <div className="print-product-item-detail">
                                <span>{price.toLocaleString("fa-IR")}</span>
                                <span>{price.toLocaleString("fa-IR")}</span>
                                <span>1</span>
                            </div>
                        </div>
                        <div className="print-product-item">
                            <div className="print-product-item-header">
                                <span>سوهان حلویی ساعدی نیا</span>
                            </div>
                            <div className="print-product-item-detail">
                                <span>{price.toLocaleString("fa-IR")}</span>
                                <span>{price.toLocaleString("fa-IR")}</span>
                                <span>1</span>
                            </div>
                        </div>
                        <div className="print-product-item">
                            <div className="print-product-item-header">
                                <span>زعفران یک مثقال پاکتی</span>
                            </div>
                            <div className="print-product-item-detail">
                                <span>{price.toLocaleString("fa-IR")}</span>
                                <span>{price.toLocaleString("fa-IR")}</span>
                                <span>1</span>
                            </div>
                        </div>
                        <div className="print-product-item">
                            <div className="print-product-item-header">
                                <span>وانیل شکری</span>
                            </div>
                            <div className="print-product-item-detail">
                                <span>{price.toLocaleString("fa-IR")}</span>
                                <span>{price.toLocaleString("fa-IR")}</span>
                                <span>1</span>
                            </div>
                        </div>
                        <div className="text-center mt-2">
                            تعداد ردیف ها: 6
                        </div>
                    </div>

                    <div className="print-invoice-detail">
                        <div className="print-invoice-detail-item">
                            <span className="print-invoice-detail-item-title">وزن مرسوله:</span>
                            <span className="print-invoice-detail-item-content">5</span>
                        </div>
                        <div className="print-invoice-detail-item">
                            <span className="print-invoice-detail-item-title">هزینه ارسال:</span>
                            <span className="print-invoice-detail-item-content">رایگان</span>
                        </div>
                        <div className="print-invoice-detail-item">
                            <span className="print-invoice-detail-item-title">مجموع فاکتور:</span>
                            <span className="print-invoice-detail-item-content">{price.toLocaleString("fa-IR")}</span>
                        </div>
                        <div className="print-invoice-detail-item">
                            <span className="print-invoice-detail-item-title">کد تخفیف:</span>
                            <span className="print-invoice-detail-item-content">5</span>
                        </div>
                        <div className="print-invoice-detail-item">
                            <span className="print-invoice-detail-item-title">جمع کد تخفیف:</span>
                            <span className="print-invoice-detail-item-content">5</span>
                        </div>
                        <div className="print-invoice-detail-item">
                            <span className="print-invoice-detail-item-title">قابل پرداخت:</span>
                            <span className="print-invoice-detail-item-content">5</span>
                        </div>
                        <div className="print-invoice-detail-item">
                            <span className="print-invoice-detail-item-title">نحوه پرداخت:</span>
                            <span className="print-invoice-detail-item-content">5</span>
                        </div>
                    </div>

                    <div className="print-card mt-2">
                        <span>تاریخ ثبت: </span>
                        <span>1401-12-12 13:15:23</span>
                    </div>
                    <div className="print-card dark mt-2">
                        <span>شماره سفارش: </span>
                        <span>140123</span>
                    </div>
                    <div className="print-card mt-2">
                        <span>پیام کاربر برای این سفارش</span>
                    </div>
                    <div className="print-card dark mt-2">
                        <span>چاپ مجدد (1)</span>
                    </div>
                    <textarea className="print-invoice-description" rows={5}>
                        توضیحات سفارش
                    </textarea>

                    <div className="print-user-address">
                        <div className="print-user-address-item">
                            <span className="print-user-address-item-title">نام و نام خانوادگی: </span>
                            <span className="print-user-address-item-content">نیلوفر سعیدی</span>
                        </div>
                        <div className="print-user-address-item">
                            <span className="print-user-address-item-title">موبایل: </span>
                            <span className="print-user-address-item-content">09128089576</span>
                            <span className="print-user-address-item-title">تلفن: </span>
                            <span className="print-user-address-item-content">02188001881</span>
                        </div>
                        <div className="print-user-address-item">
                            <span className="print-user-address-item-title">استان: </span>
                            <span className="print-user-address-item-content">تهران</span>
                            <span className="print-user-address-item-title">شهر: </span>
                            <span className="print-user-address-item-content">تهران</span>
                        </div>
                        <div className="print-user-address-item">
                            <span className="print-user-address-item-title">آدرس: </span>
                            <span className="print-user-address-item-content">خیابان دکتر فاطمی، خیابان رهی معیری، بن بست نیاک، پلاک 12 واحد 3</span>
                            <span className="print-user-address-item-title">کد پستی: </span>
                            <span className="print-user-address-item-content">0000000000</span>
                        </div>
                    </div>
                </div>
                <p className="print-footer mt-2">Powered By HosseiniBrothers.com</p>

            </div>
        </div>
    )
}