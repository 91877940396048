import {useState} from "react";
import {useNavigate} from "react-router-dom";
import {login, setToken} from "../../services";
import {useFormik} from "formik";
import {initialValues, validationSchema} from "../../utils/formsValidator/loginFormValidator";
import {useAuthAction} from "../../context/AuthContext";
import {pushToast} from "../../utils/pushToast";
import Loading from "../../components/Loading";
import Input from "../../components/Input";
import InputPassword from "../../components/InputPassword";

export default function Login() {

    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const setAuth = useAuthAction();

    const onSubmit = async (values) => {
        setIsLoading(true);
        try {
            const {userInfo, userPermission} = await login(values);
            if (userInfo.isSuccess) {
                setToken(userInfo?.data?.token)
                setAuth({userInfo: userInfo?.data, userPermission});
                navigate('/dashboard');
            } else {
                formik.resetForm();
                pushToast("اسم المستخدم وكلمة المرور غير صحيحة.", "error")
            }
            setIsLoading(false);
        } catch (ex) {
            console.log(ex)
            formik.resetForm();
            pushToast("اسم المستخدم وكلمة المرور غير صحيحة.", "error")
            setIsLoading(false);
        }
    }

    const formik = useFormik({
        initialValues,
        onSubmit,
        validationSchema,
        validateOnMount: false,
        validateOnChange: true
    });

    return (
        <>
            <div className="container-xxl">
                <div className="authentication-wrapper authentication-basic container-p-y">
                    <div className="authentication-inner py-4">
                        <div className="card">
                            <div className="card-body">
                                <div className="app-brand justify-content-center">
                                    <img src="/logo.png" className={'img-fluid'} />
                                </div>
                                <h4 className="mb-2">أدخل معلومات المستخدم الخاصة بك.</h4>

                                <form id="formAuthentication"
                                      className={`needs-validation mb-3 ${!formik.isValid ? "was-validated" : ""}`}
                                      noValidate={true} onSubmit={formik.handleSubmit}
                                      autoComplete="off"
                                >
                                    <div className="mb-3 position-relative">
                                        <Input
                                            type="text"
                                            className="text-start"
                                            dir="ltr"
                                            name="mobile"
                                            placeholder="أدخل رقم هاتفك المحمول"
                                            autoComplete="off"
                                            label="رقم الهاتف المحمول"
                                            formik={formik}
                                        />
                                    </div>

                                    <div className="mb-3 form-password-toggle">
                                        <InputPassword
                                            className="text-start"
                                            dir="ltr"
                                            name="password"
                                            placeholder="..........."
                                            formik={formik}
                                            label="كلمة المرور"
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <button className="btn btn-primary d-grid w-100" type="submit"
                                        >تسجيل الدخول
                                            {
                                                isLoading && <Loading/>
                                            }
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>)
}