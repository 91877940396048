export default function Dashboard() {
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <h6 className="mt-2 fw-normal text-success">فروشگاه</h6>
                    <hr className="mt-0"/>
                </div>
            </div>
        </>
    )
}