export default function Toggle({type, formik, label, className = "", onChange, checked, disabled = false, classNameInput, name, id, value}) {

    const handleChange = e => {
        onChange && onChange(e)
    }

    return (
        <div className={`form-check position-relative ${className}`}>
            <input className={`form-check-input ${classNameInput} ${formik.errors[name] && formik.touched[name] ? "is-invalid" : ""}`} type={type} value={value} onChange={handleChange} id={id}
                   checked={checked} disabled={disabled} name={name}/>
            {
                label && (<label className="form-check-label" htmlFor={id}>{label}</label>)
            }
          {
            formik.touched[name] && formik.errors[name] ? (<div className="invalid-feedback">{formik.errors[name]}</div>) : null
          }
        </div>
    )
}
