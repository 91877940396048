export default function Footer() {
    return (
        <footer className="content-footer footer bg-footer-theme">
            <div className="container-fluid d-flex flex-wrap justify-content-between py-2 flex-md-row flex-column">
                <div className="mb-2 mb-md-0">
                   <a href={"https://pasavan.ir"} target={"_blank"}>طراحی توسط شرکت پاساوان</a>
                </div>
            </div>
        </footer>
    )
}