import React, {useContext} from 'react';

const defaultBehaviour = {isAllowedTo: () => false}

export const PermissionContext = React.createContext(defaultBehaviour);

const PermissionProvider = ({permissions, children}) => {

    const isAllowedTo = (permission) => {
        if (permission === "") return true
        return permissions?.includes(permission)
    };

    return <PermissionContext.Provider value={{isAllowedTo}}>{children}</PermissionContext.Provider>;
};

export default PermissionProvider;

export const usePermission = (permission) => {
    const {isAllowedTo} = useContext(PermissionContext);
    return isAllowedTo(permission);
}