import {BrowserRouter, Route, Routes} from "react-router-dom";
import {mainRoute} from "./routes";
import {Suspense} from "react";
import {ToastContainer} from "react-toastify";
import {AuthContextProvider} from "./context/AuthContext";
import Loading from "./components/Loading";
import SweetAlertContextProvider from "./context/sweetAlertContext";
import {ToggleSidebarContextProvider} from "./context/toggleSidebarContext";

export default function App() {

    return (
        <ToggleSidebarContextProvider>
            <AuthContextProvider>
                <SweetAlertContextProvider>
                    <Suspense fallback={<Loading/>}>
                        <BrowserRouter>
                            <Routes>
                                {
                                    mainRoute.map(route => {
                                        return <Route path={route.path} key={route.id}
                                                      element={<route.component/>}/>
                                    })
                                }
                            </Routes>
                            <ToastContainer
                                rtl={true}
                                theme="colored"
                            />
                        </BrowserRouter>
                    </Suspense>
                </SweetAlertContextProvider>
            </AuthContextProvider>
        </ToggleSidebarContextProvider>
    );
}
