import Login from "../pages/auth/login";
import Register from "../pages/auth/register";
import ForgetPassword from "../pages/auth/forget-password";
import ResetPassword from "../pages/auth/reset-password";
import VerifyEmail from "../pages/auth/verify-email";
import AdminLayout from "../layouts/adminLayout";
import Sample from "../pages/Sample";
import Print from "../pages/Print";

import {lazy} from "react";

const Dashboard = lazy(() => import("../pages/Dashboard"));

const UsersList = lazy(() => import("../pages/User/List"));
const UserNew = lazy(() => import("../pages/User/New"));
const UserEdit = lazy(() => import("../pages/User/Edit"));
const UserRole = lazy(() => import("../pages/User/UserRole"));
const RoleList = lazy(() => import("../pages/Role/List"));
const RoleNew = lazy(() => import("../pages/Role/New"));
const RoleEdit = lazy(() => import("../pages/Role/Edit"));
const UpdateRolePermission = lazy(() => import("../pages/Role/UpdateRolePermission"));

const PermissionList = lazy(() => import("../pages/Permission/List"))
const PermissionNew = lazy(() => import("../pages/Permission/New"));
const PermissionEdit = lazy(() => import("../pages/Permission/Edit"))

const PermissionCategoryList = lazy(() => import("../pages/PermissionCategory/List"));
const PermissionCategoryNew = lazy(() => import("../pages/PermissionCategory/New"));
const PermissionCategoryEdit = lazy(() => import("../pages/PermissionCategory/Edit"));

const ProvinceList = lazy(() => import("../pages/Province/List"));
const ProvinceNew = lazy(() => import("../pages/Province/New"));
const ProvinceEdit = lazy(() => import("../pages/Province/Edit"));
const ListCities = lazy(() => import("../pages/Province/ListCities"));
const AddCity = lazy(() => import("../pages/Province/AddCity"));
const EditCity = lazy(() => import("../pages/Province/EditCity"));

const CityList = lazy(() => import("../pages/City/List"));
const CityNew = lazy(() => import("../pages/City/New"));
const CityEdit = lazy(() => import("../pages/City/Edit"));

const HashtagList = lazy(() => import("../pages/Hashtag/List"));
const HashtagNew = lazy(() => import("../pages/Hashtag/New"));
const HashtagEdit = lazy(() => import("../pages/Hashtag/Edit"));

const ProductCategoryList = lazy(() => import("../pages/ProductCategory/List"));
const ProductCategoryNew = lazy(() => import("../pages/ProductCategory/New"));
const ProductCategoryEdit = lazy(() => import("../pages/ProductCategory/Edit"));
const ListProducts = lazy(() => import("../pages/ProductCategory/ListProducts"));
const AddProduct = lazy(() => import ("../pages/ProductCategory/AddProduct"));
const EditProduct = lazy(() => import ("../pages/ProductCategory/EditProduct"));

const FrequentlyQuestionCategoryList = lazy(() => import("../pages/FrequentlyQuestionCategory/List"));
const FrequentlyQuestionCategoryNew = lazy(() => import("../pages/FrequentlyQuestionCategory/New"));
const FrequentlyQuestionCategoryEdit = lazy(() => import("../pages/FrequentlyQuestionCategory/Edit"));
const ListFrequentlyQuestion = lazy(() => import("../pages/FrequentlyQuestionCategory/ListFrequentlyQuestion"));
const AddFrequentlyQuestion = lazy(() => import("../pages/FrequentlyQuestionCategory/AddFrequentlyQuestion"));
const EditFrequentlyQuestion = lazy(() => import("../pages/FrequentlyQuestionCategory/EditFrequentlyQuestion"));

const FrequentlyQuestionList = lazy(() => import("../pages/FrequentlyQuestion/List"));
const FrequentlyQuestionNew = lazy(() => import("../pages/FrequentlyQuestion/New"));
const FrequentlyQuestionEdit = lazy(() => import("../pages/FrequentlyQuestion/Edit"));

const ArticleCategoryList = lazy(() => import("../pages/ArticleCategory/List"));
const ArticleCategoryNew = lazy(() => import("../pages/ArticleCategory/New"));
const ArticleCategoryEdit = lazy(() => import("../pages/ArticleCategory/Edit"));

const ArticleList = lazy(() => import("../pages/Article/List"));
const ArticleNew = lazy(() => import("../pages/Article/New"));
const ArticleEdit = lazy(() => import("../pages/Article/Edit"));

const ProductList = lazy(() => import("../pages/Product/List"));
const ProductNew = lazy(() => import("../pages/Product/New"));
const ProductEdit = lazy(() => import("../pages/Product/Edit"));

const UnitList = lazy(() => import("../pages/Unit/List"));
const UnitNew = lazy(() => import("../pages/Unit/New"));
const UnitEdit = lazy(() => import("../pages/Unit/Edit"));

const BannerList = lazy(() => import("../pages/Banner/List"));
const BannerNew = lazy(() => import("../pages/Banner/New"));
const BannerEdit = lazy(() => import("../pages/Banner/Edit"));

const ProductSliderList = lazy(() => import("../pages/ProductSlider/List"));
const ProductSliderNew = lazy(() => import("../pages/ProductSlider/New"));
const ProductSliderEdit = lazy(() => import("../pages/ProductSlider/Edit"));

const SendPriceList = lazy(() => import("../pages/SendPrice/List"));
const SendPriceNew = lazy(() => import("../pages/SendPrice/New"));
const SendPriceEdit = lazy(() => import("../pages/SendPrice/Edit"));

const SendCompanyList = lazy(() => import("../pages/SendCompany/List"));
const SendCompanyNew = lazy(() => import("../pages/SendCompany/New"));
const SendCompanyEdit = lazy(() => import("../pages/SendCompany/Edit"));

const PaymentTypeList = lazy(() => import("../pages/PaymentType/List"));
const PaymentTypeNew = lazy(() => import("../pages/PaymentType/New"));
const PaymentTypeEdit = lazy(() => import("../pages/PaymentType/Edit"));

const DiscountList = lazy(() => import("../pages/Discount/List"));
const DiscountNew = lazy(() => import("../pages/Discount/New"));
const DiscountEdit = lazy(() => import("../pages/Discount/Edit"));

const CommentList = lazy(() => import("../pages/Comment/List"));
const CommentEdit = lazy(() => import("../pages/Comment/Edit"));

const OrderList = lazy(() => import("../pages/Order/List"));
const OrderNew = lazy(() => import("../pages/Order/New"));
const OrderEdit = lazy(() => import("../pages/Order/Edit"));
const HomePage = lazy(()=>import("../pages/Frontend/HomePage"))
const Menu = lazy(()=>import("../pages/Frontend/Menu"))
export const mainRoute = [
    {
        id: 0,
        path: '/*',
        component: AdminLayout,
        isProtected: true
    },
    {
        id: 1,
        title: "الملوک",
        path: '/',
        component: HomePage,
        isProtected: false
    },
    {
        id: 1,
        title: "منو",
        path: '/menu',
        component: Menu,
        isProtected: false
    },
    {
        id: 1,
        title: "ورود",
        path: '/login',
        component: Login,
        isProtected: false
    },
    {
        id: 2,
        title: "ثبت نام",
        path: '/register',
        component: Register,
        isProtected: false
    },
    {
        id: 3,
        title: "فراموشی رمز عبور",
        path: '/forget-password',
        component: ForgetPassword,
        isProtected: false
    },
    {
        id: 4,
        title: "بازنشانی رمز عبور",
        path: '/reset-password',
        component: ResetPassword,
        isProtected: false
    },
    {
        id: 5,
        title: "تایید ایمیل",
        path: '/verify-email',
        component: VerifyEmail,
        isProtected: false
    },
    {
        id: 6,
        title: "تایید ایمیل",
        path: '/sample',
        component: Sample,
        isProtected: false
    },
    {
        id: 7,
        title: "چاپ فاکتور",
        path: '/print',
        component: Print,
        isProtected: false
    }
]

export const panelRoutes = [
    {
        id: 0,
        title: "داشبورد",
        path: "/dashboard",
        component: Dashboard,
        permission: ""
    },
    {
        id: 1,
        title: "کاربران | لیست",
        path: "/user/list",
        component: UsersList,
        permission: "UserUserList"
    },
    {
        id: 2,
        title: "کاربران | جدید",
        path: "/user/new",
        component: UserNew,
        permission: "UserAddNewUser"
    },
    {
        id: 3,
        title: "کاربران | ویرایش",
        path: "/user/edit/:id",
        component: UserEdit,
        permission: "UserUserItem"
    },
    {
        id: 4,
        title: "کاربران | ویرایش نقش",
        path: "/user/user-role/:id",
        component: UserRole,
        permission: "UserUserItem"
    },
    {
        id: 5,
        title: "نقش ها | لیست",
        path: "/config/role/list",
        component: RoleList,
        permission: "RoleRoleList"
    },
    {
        id: 6,
        title: "نقش ها | جدید",
        path: "/config/role/new",
        component: RoleNew,
        permission: "RoleAddRole"
    },
    {
        id: 7,
        title: "نقش ها | ویرایش",
        path: "/config/role/edit/:id",
        component: RoleEdit,
        permission: "RoleGetRoleItem"
    },
    {
        id: 8,
        title: "سطح دسترسی نقش ها | ویرایش",
        path: "/config/role/update-permission/:id",
        component: UpdateRolePermission,
        permission: "RoleGetRoleItem"
    },
    {
        id: 9,
        title: "سطح دسترسی ها | لیست",
        path: "/config/permission/list",
        component: PermissionList,
        permission: "PermissionPermissionList"
    },
    {
        id: 10,
        title: "سطح دسترسی ها | جدید",
        path: "/config/permission/new",
        component: PermissionNew,
        permission: "PermissionAddPermission"
    },
    {
        id: 11,
        title: "سطح دسترسی ها | ویرایش",
        path: "/config/permission/edit/:id",
        component: PermissionEdit,
        permission: "PermissionGetPermissionItem"
    },
    {
        id: 12,
        title: "دسته بندی سطح دسترسی ها | لیست",
        path: "/config/permission-category/list",
        component: PermissionCategoryList,
        permission: "PermissionCategoryPermissionCategoryList"
    },
    {
        id: 13,
        title: "دسته بندی سطح دسترسی ها | جدید",
        path: "/config/permission-category/new",
        component: PermissionCategoryNew,
        permission: "PermissionCategoryAddPermissionCategory"
    },
    {
        id: 14,
        title: "دسته بندی سطح دسترسی ها | ویرایش",
        path: "/config/permission-category/edit/:id",
        component: PermissionCategoryEdit,
        permission: "PermissionCategoryPermissionCategoryItem"
    },
    {
        id: 15,
        title: "استان ها | لیست",
        path: "/province/list",
        component: ProvinceList,
        permission: "ProvinceProvinceList"
    },
    {
        id: 16,
        title: "استان ها | جدید",
        path: "/province/new",
        component: ProvinceNew,
        permission: "ProvinceAddProvince"
    },
    {
        id: 17,
        title: "استان ها | ویرایش",
        path: "/province/edit/:id",
        component: ProvinceEdit,
        permission: "ProvinceProvinceItem"
    },
    {
        id: 18,
        title: "شهر های استان | لیست",
        path: "/province/city-list/:id",
        component: ListCities,
        permission: "ProvinceProvinceItem"
    },
    {
        id: 19,
        title: "شهر ها | جدید",
        path: "/province/:provinceId/add-city",
        component: AddCity,
        permission: "CityAddCity"
    },
    {
        id: 20,
        title: "شهر ها | ویرایش",
        path: "/province/:provinceId/edit-city/:cityId",
        component: EditCity,
        permission: "CityCityItem"
    },
    {
        id: 21,
        title: "شهر ها | لیست",
        path: "/city/list",
        component: CityList,
        permission: "CityCityList"
    },
    {
        id: 22,
        title: "شهر ها | جدید",
        path: "/city/new",
        component: CityNew,
        permission: "CityAddCity"
    },
    {
        id: 23,
        title: "شهر ها | ویرایش",
        path: "/city/edit/:id",
        component: CityEdit,
        permission: "CityCityItem"
    },
    {
        id: 24,
        title: "هشتگ ها | لیست",
        path: "/hashtag/list",
        component: HashtagList,
        permission: "HashtagHashtagList"
    },
    {
        id: 25,
        title: "هشتگ ها | جدید",
        path: "/hashtag/new",
        component: HashtagNew,
        permission: "HashtagAddHashtag"
    },
    {
        id: 26,
        title: "هشتگ ها | ویرایش",
        path: "/hashtag/edit/:id",
        component: HashtagEdit,
        permission: "HashtagHashtagItem"
    },
    {
        id: 27,
        title: "دسته بندی محصولات | لیست",
        path: "/product-category/list",
        component: ProductCategoryList,
        permission: "ProductCategoryProductCategoryList"
    },
    {
        id: 28,
        title: "دسته بندی محصولات | لیست",
        path: "/product-category/list/:id",
        component: ProductCategoryList,
        permission: "ProductCategoryProductCategoryList"
    },
    {
        id: 29,
        title: "دسته بندی محصولات | جدید",
        path: "/product-category/new",
        component: ProductCategoryNew,
        permission: "ProductCategoryAddProductCategory"
    },
    {
        id: 30,
        title: "دسته بندی محصولات | جدید",
        path: "/product-category/new/:productCategoryId",
        component: ProductCategoryNew,
        permission: "ProductCategoryAddProductCategory"
    },
    {
        id: 31,
        title: "دسته بندی محصولات | جدید",
        path: "/product-category/new/:productCategoryId",
        component: ProductCategoryNew,
        permission: "ProductCategoryAddProductCategory"
    },
    {
        id: 32,
        title: "دسته بندی محصولات | ویرایش",
        path: "/product-category/edit/:id",
        component: ProductCategoryEdit,
        permission: "ProductCategoryProductCategoryItem"
    },
    {
        id: 33,
        title: "محصولات | جدید",
        path: "/product-category/:productCategoryId/add-product",
        component: AddProduct,
        permission: "ProductAddProduct"
    },
    {
        id: 34,
        title: "محصولات | ویرایش",
        path: "/product-category/:productCategoryId/edit-product/:productId",
        component: EditProduct,
        permission: "ProductEditProduct"
    },

    {
        id: 35,
        title: "دسته بندی سوالات متداول | لیست",
        path: "/frequently-question-category/list",
        component: FrequentlyQuestionCategoryList,
        permission: "FrequentlyQuestionCategoryFrequentlyQuestionCategoryList"
    },
    {
        id: 36,
        title: "دسته بندی سوالات متداول | جدید",
        path: "/frequently-question-category/new",
        component: FrequentlyQuestionCategoryNew,
        permission: "FrequentlyQuestionCategoryAddFrequentlyQuestionCategory"
    },
    {
        id: 37,
        title: "دسته بندی سوالات متداول | ویرایش",
        path: "/frequently-question-category/edit/:id",
        component: FrequentlyQuestionCategoryEdit,
        permission: "FrequentlyQuestionCategoryFrequentlyQuestionCategoryItem"
    },
    {
        id: 38,
        title: "سوالات متداول | لیست",
        path: "/frequently-question-category/frequently-question-list/:id",
        component: ListFrequentlyQuestion,
        permission: "FrequentlyQuestionFrequentlyQuestionList"
    },
    {
        id: 39,
        title: "سوالات متداول | جدید",
        path: "/frequently-question-category/:frequentlyQuestionCategoryId/add-frequently-question",
        component: AddFrequentlyQuestion,
        permission: "FrequentlyQuestionAddFrequentlyQuestion"
    },
    {
        id: 40,
        title: "سوالات متداول | ویرایش",
        path: "/frequently-question-category/:frequentlyQuestionCategoryId/add-frequently-question/:id",
        component: EditFrequentlyQuestion,
        permission: "FrequentlyQuestionEditFrequentlyQuestion"
    },
    {
        id: 41,
        title: "سوالات متداول | لیست",
        path: "/frequently-question/list",
        component: FrequentlyQuestionList,
        permission: "FrequentlyQuestionFrequentlyQuestionList"
    },
    {
        id: 42,
        title: "سوالات متداول | جدید",
        path: "/frequently-question/new",
        component: FrequentlyQuestionNew,
        permission: "FrequentlyQuestionAddFrequentlyQuestion"
    },
    {
        id: 43,
        title: "سوالات متداول | ویرایش",
        path: "/frequently-question/edit/:id",
        component: FrequentlyQuestionEdit,
        permission: "FrequentlyQuestionFrequentlyQuestionItem"
    },
    {
        id: 44,
        title: "دسته بندی مقالات | لیست",
        path: "/article-category/list",
        component: ArticleCategoryList,
        permission: "ArticleCategoryArticleCategoryList"
    },
    {
        id: 45,
        title: "دسته بندی مقالات | لیست",
        path: "/article-category/list/:id",
        component: ArticleCategoryList,
        permission: "ArticleCategoryArticleCategoryList"
    },
    {
        id: 46,
        title: "دسته بندی مقالات | جدید",
        path: "/article-category/new",
        component: ArticleCategoryNew,
        permission: "ArticleCategoryAddArticleCategory"
    },
    {
        id: 47,
        title: "دسته بندی مقالات | جدید",
        path: "/article-category/new/:articleCategoryId",
        component: ArticleCategoryNew,
        permission: "ArticleCategoryAddArticleCategory"
    },
    {
        id: 48,
        title: "دسته بندی مقالات | ویرایش",
        path: "/article-category/edit/:id",
        component: ArticleCategoryEdit,
        permission: "ArticleCategoryArticleCategoryItem"
    },
    {
        id: 49,
        title: "مقالات | لیست",
        path: "/article/list",
        component: ArticleList,
        permission: "ArticleArticleList"
    },
    {
        id: 50,
        title: "مقالات | جدید",
        path: "/article/new",
        component: ArticleNew,
        permission: "ArticleAddArticle"
    },
    {
        id: 51,
        title: "مقالات | ویرایش",
        path: "/article/edit/:id",
        component: ArticleEdit,
        permission: "ArticleArticleItem"
    },
    {
        id: 52,
        title: "محصولات | لیست",
        path: "/product/list",
        component: ProductList,
        permission: "ProductProductList"
    },
    {
        id: 53,
        title: "محصولات | جدید",
        path: "/product/new",
        component: ProductNew,
        permission: "ProductAddProduct"
    },
    {
        id: 54,
        title: "محصولات | ویرایش",
        path: "/product/edit/:id",
        component: ProductEdit,
        permission: "ProductProductItem"
    },
    {
        id: 55,
        title: "محصولات | لیست",
        path: "/unit/list",
        component: UnitList,
        permission: "UnitUnitList"
    },
    {
        id: 56,
        title: "محصولات | جدید",
        path: "/unit/new",
        component: UnitNew,
        permission: "UnitAddUnit"
    },
    {
        id: 57,
        title: "محصولات | ویرایش",
        path: "/unit/edit/:id",
        component: UnitEdit,
        permission: "UnitUnitItem"
    },
    {
        id: 58,
        title: "محصولات | لیست",
        path: "/product-category/product-list/:id",
        component: ListProducts,
        permission: "ProductProductList"
    },
    {
        id: 59,
        title: "بنر | جدید",
        path: "/banner/new",
        component: BannerNew,
        permission: "BannerAddBanner"
    },
    {
        id: 60,
        title: "بنر | ویرایش",
        path: "/banner/edit/:id",
        component: BannerEdit,
        permission: "BannerBannerItem"
    },
    {
        id: 61,
        title: "بنر | لیست",
        path: "/banner/list",
        component: BannerList,
        permission: "BannerBannerList"
    },
    {
        id: 62,
        title: "اسلایدر محصولات | جدید",
        path: "/product-slider/new",
        component: ProductSliderNew,
        permission: "ProductSliderAddProductSlider"
    },
    {
        id: 63,
        title: "اسلایدر محصولات | ویرایش",
        path: "/product-slider/edit/:id",
        component: ProductSliderEdit,
        permission: "ProductSliderProductSliderItem"
    },
    {
        id: 64,
        title: "اسلایدر محصولات | لیست",
        path: "/product-slider/list",
        component: ProductSliderList,
        permission: "ProductSliderProductSliderList"
    },
    {
        id: 65,
        title: "هزینه ارسال | جدید",
        path: "/send-price/new",
        component: SendPriceNew,
        permission: "SendPriceAddSendPrice"
    },
    {
        id: 66,
        title: "هزینه ارسال | ویرایش",
        path: "/send-price/edit/:id",
        component: SendPriceEdit,
        permission: "SendPriceSendPriceItem"
    },
    {
        id: 67,
        title: "هزینه ارسال | لیست",
        path: "/send-price/list",
        component: SendPriceList,
        permission: "SendPriceSendPriceList"
    },
    {
        id: 68,
        title: "شرکت ارسال کننده | جدید",
        path: "/send-company/new",
        component: SendCompanyNew,
        permission: "SendCompanyAddSendCompany"
    },
    {
        id: 69,
        title: "شرکت ارسال کننده | ویرایش",
        path: "/send-company/edit/:id",
        component: SendCompanyEdit,
        permission: "SendCompanySendCompanyItem"
    },
    {
        id: 70,
        title: "شرکت ارسال کننده | لیست",
        path: "/send-company/list",
        component: SendCompanyList,
        permission: "SendCompanySendCompanyList"
    },
    {
        id: 71,
        title: "نوع پرداخت | جدید",
        path: "/payment-type/new",
        component: PaymentTypeNew,
        permission: "PaymentTypeAddPaymentType"
    },
    {
        id: 72,
        title: "نوع پرداخت | ویرایش",
        path: "/payment-type/edit/:id",
        component: PaymentTypeEdit,
        permission: "PaymentTypePaymentTypeItem"
    },
    {
        id: 73,
        title: "نوع پرداخت | لیست",
        path: "/payment-type/list",
        component: PaymentTypeList,
        permission: "PaymentTypePaymentTypeList"
    },
    {
        id: 74,
        title: "تخفیف | جدید",
        path: "/discount/new",
        component: DiscountNew,
        permission: "DiscountAddDiscount"
    },
    {
        id: 75,
        title: "تخفیف | ویرایش",
        path: "/discount/edit/:id",
        component: DiscountEdit,
        permission: "DiscountDiscountItem"
    },
    {
        id: 76,
        title: "تخفیف | لیست",
        path: "/discount/list",
        component: DiscountList,
        permission: "DiscountDiscountList"
    },
    {
        id: 77,
        title: "نظر | ویرایش",
        path: "/comment/edit/:id",
        component: CommentEdit,
        permission: "CommentCommentItem"
    },
    {
        id: 78,
        title: "نظر | لیست",
        path: "/comment/list",
        component: CommentList,
        permission: "CommentCommentList"
    },
    {
        id: 79,
        title: "سفارشات | جدید",
        path: "/order/new",
        component: OrderNew,
        permission: "BasketAddBasket"
    },
    {
        id: 80,
        title: "سفارشات | ویرایش",
        path: "/order/edit/:id",
        component: OrderEdit,
        permission: "BasketBasketItem"
    },
    {
        id: 81,
        title: "سفارشات | لیست",
        path: "/order/list",
        component: OrderList,
        permission: "BasketBasketList"
    }
]
