import {usePermission} from "./PermissionContext";
import NotFound from "../pages/NotFound";

const Restricted = ({to, children, replace = false}) => {

    const allowed = usePermission(to)

    if (allowed) {
        return <>{children}</>;
    }

    return (
        replace ? <><NotFound/></> : null
    )
};

export default Restricted;