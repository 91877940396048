import axios from "axios";
import {pushToast} from "../utils/pushToast";

const apiUrl = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PROD_API_KEY : process.env.REACT_APP_DEV_API_KEY;

const client = axios.create({
    baseURL: apiUrl,
})

client.defaults.headers.common.Accept = 'application/json';
client.defaults.headers.common['Accept-Language'] = "fa";
client.defaults.headers.common['Content-Type'] = 'application/json';
client.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
client.interceptors.request.use(request => {
    return request;
})

client.interceptors.response.use((response) => {
    return response;
}, (error) => {
    if (error.response.status === 401) {
        removeToken();
        window.location.replace("/login")
    }
    if (error.response.status === 403) {
        pushToast("خطای دسترسی", "error")
    }
    return Promise.reject(error)
})

export const setToken = (token) => {
    localStorage.setItem('token', token);
    client.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}

const removeToken = () => {
    localStorage.removeItem('token');
    client.defaults.headers.common['Authorization'] = "";
}

export const login = async (values) => {
    client.defaults.headers.common['Authorization'] = ``;
    const {data} = await client.post(`Authentication/LoginUser`, values);
    return data;
}

export const me = async () => {
    const {data} = await client.post(`User/UserInfo`);
    return data;
}

export const logout = async (values) => {
    const {data} = await client.post(`Authentication/Logout`, values);
    removeToken();
    return data;
}

export const register = (values) => {
    const {data} = client.post(`Authentication/RegisterUser`, values);
    setToken(data.token);
    return data;
}

export const forgetPassword = (values) => {
    const {data} = client.post(`forget-password`, values);
    return data;
}

export const resetPassword = (values) => {
    const {data} = client.post(`reset-password`, values);
    return data;
}

export const verifyEmail = () => {
    const {data} = client.get(`verify-email`);
    return data;
}


// common

export const languages = async () => {
    const {data} = await client.get(`Common/GetListLanguage`);
    return data;
}

// User
export const users = async (values) => {
    const {data} = await client.post(`User/UserList`, values);
    return data;
}

export const user = async (id) => {
    const {data} = await client.get(`User/UserItem/${id}`);
    return data;
}

export const createUser = async (values) => {
    const {data} = await client.post(`User/AddNewUser`, values)
    return data;
}

export const updateUser = async (id, values) => {
    const {data} = await client.patch(`User/EditUser/${id}`, values)
    return data;
}

export const deleteUser = async (id) => {
    const {data} = await client.delete(`User/RemoveUser/${id}`)
    return data;
}

export const updateUserRole = async (values) => {
    const {data} = await client.patch(`User/UpdateUserRole`, values)
    return data;
}

// permission category

export const permissionCategories = async (values) => {
    const {data} = await client.post(`PermissionCategory/PermissionCategoryList`, values);
    return data;
}

export const permissionCategory = async (id) => {
    const {data} = await client.get(`PermissionCategory/PermissionCategoryItem/${id}`);
    return data;
}

export const createPermissionCategory = async (values) => {
    const {data} = await client.post(`PermissionCategory/AddPermissionCategory`, values);
    return data;
}

export const deletePermissionCategory = async (id) => {
    const {data} = await client.delete(`PermissionCategory/RemovePermissionCategory/${id}`);
    return data;
}

export const updatePermissionCategory = async (id, values) => {
    const {data} = await client.patch(`PermissionCategory/EditPermissionCategory/${id}`, values);
    return data;
}

export const permissionCategoryList = async () => {
    const {data} = await client.get(`Permission/GetPermissionWithCategoryList`);
    return data;
}

// Role
export const roles = async (values) => {
    const {data} = await client.post(`Role/RoleList`, values);
    return data;
}

export const role = async (id) => {
    const {data} = await client.get(`Role/GetRoleItem/${id}`);
    return data;
}

export const createRole = async (values) => {
    const {data} = await client.post(`Role/AddRole`, values);
    return data;
}

export const deleteRole = async (id) => {
    const {data} = await client.delete(`Role/RemoveRole/${id}`);
    return data;
}

export const updateRole = async (id, values) => {
    const {data} = await client.patch(`Role/EditRole/${id}`, values);
    return data;
}

export const updateRolePermission = async (values) => {
    const {data} = await client.patch(`Role/UpdateRolePermission`, values);
    return data;
}

//permission
export const permissions = async (values) => {
    const {data} = await client.post(`Permission/PermissionList`, values);
    return data;
}

export const permission = async (id) => {
    const {data} = await client.get(`Permission/GetPermissionItem/${id}`);
    return data;
}

export const createPermission = async (values) => {
    const {data} = await client.post(`Permission/AddPermission`, values)
    return data;
}

export const deletePermission = async (id) => {
    const {data} = await client.delete(`Permission/RemovePermission/${id}`);
    return data;
}

export const updatePermission = async (id, values) => {
    const {data} = await client.patch(`Permission/EditPermission/${id}`, values);
    return data;
}


// province
export const provinces = async (values) => {
    const {data} = await client.post(`Province/ProvinceList`, values);
    return data;
}

export const province = async (id) => {
    const {data} = await client.get(`Province/ProvinceItem/${id}`);
    return data;
}

export const createProvince = async (values) => {
    const {data} = await client.post(`Province/AddProvince`, values)
    return data;
}

export const deleteProvince = async (id) => {
    const {data} = await client.delete(`Province/RemoveProvince/${id}`);
    return data;
}

export const updateProvince = async (id, values) => {
    const {data} = await client.patch(`Province/EditProvince/${id}`, values);
    return data;
}


// cities

export const cities = async (values) => {
    const {data} = await client.post(`City/CityList`, values);
    return data;
}

export const city = async (id) => {
    const {data} = await client.get(`City/CityItem/${id}`);
    return data;
}

export const createCity = async (values) => {
    const {data} = await client.post(`City/AddCity`, values)
    return data;
}

export const deleteCity = async (id) => {
    const {data} = await client.delete(`City/RemoveCity/${id}`);
    return data;
}

export const updateCity = async (id, values) => {
    const {data} = await client.patch(`City/EditCity/${id}`, values);
    return data;
}

// hashtags

export const hashtags = async (values) => {
    const {data} = await client.post(`Hashtag/HashtagList`, values);
    return data;
}

export const hashtag = async (id) => {
    const {data} = await client.get(`Hashtag/HashtagItem/${id}`);
    return data;
}

export const createHashtag = async (values) => {
    const {data} = await client.post(`Hashtag/AddHashtag`, values)
    return data;
}

export const deleteHashtag = async (id) => {
    const {data} = await client.delete(`Hashtag/RemoveHashtag/${id}`);
    return data;
}

export const updateHashtag = async (id, values) => {
    const {data} = await client.patch(`Hashtag/EditHashtag/${id}`, values);
    return data;
}


// product category

export const productCategories = async (values) => {
    const {data} = await client.post(`ProductCategory/ProductCategoryList`, values);
    return data;
}

export const getAllProductCategories = async (values) => {
    const {data} = await client.post(`ProductCategory/GetAllProductCategoryList`, values);
    return data;
}

export const productCategory = async (id) => {
    const {data} = await client.get(`ProductCategory/ProductCategoryItem/${id}`);
    return data;
}

export const createProductCategory = async (values) => {
    const {data} = await client.post(`ProductCategory/AddProductCategory`, values, {
        headers: {"Content-Type": "multipart/form-data"}
    })
    return data;
}

export const deleteProductCategory = async (id) => {
    const {data} = await client.delete(`ProductCategory/RemoveProductCategory/${id}`);
    return data;
}
export const upProductCategory = async (id) => {
    const {data} = await client.get(`ProductCategory/up/${id}`);
    return data;
}
export const downProductCategory = async (id) => {
    const {data} = await client.get(`ProductCategory/down/${id}`);
    return data;
}
export const updateProductCategory = async (id, values) => {
    const {data} = await client.patch(`ProductCategory/EditProductCategory/${id}`, values, {
        headers: {"Content-Type": "multipart/form-data"}
    });
    return data;
}


// frequentlyQuestionCategory

export const frequentlyQuestionCategories = async (values) => {
    const {data} = await client.post(`FrequentlyQuestionCategory/FrequentlyQuestionCategoryList`, values);
    return data;
}

export const frequentlyQuestionCategory = async (id) => {
    const {data} = await client.get(`FrequentlyQuestionCategory/FrequentlyQuestionCategoryItem/${id}`);
    return data;
}

export const createFrequentlyQuestionCategory = async (values) => {
    const {data} = await client.post(`FrequentlyQuestionCategory/AddFrequentlyQuestionCategory`, values, {
        headers: {"Content-Type": "multipart/form-data"}
    })
    return data;
}

export const deleteFrequentlyQuestionCategory = async (id) => {
    const {data} = await client.delete(`FrequentlyQuestionCategory/RemoveFrequentlyQuestionCategory/${id}`);
    return data;
}

export const updateFrequentlyQuestionCategory = async (id, values) => {
    const {data} = await client.patch(`FrequentlyQuestionCategory/EditFrequentlyQuestionCategory/${id}`, values, {
        headers: {"Content-Type": "multipart/form-data"}
    });
    return data;
}

// frequentlyQuestion

export const frequentlyQuestions = async (values) => {
    const {data} = await client.post(`FrequentlyQuestion/FrequentlyQuestionList`, values);
    return data;
}

export const frequentlyQuestion = async (id) => {
    const {data} = await client.get(`FrequentlyQuestion/FrequentlyQuestionItem/${id}`);
    return data;
}

export const createFrequentlyQuestion = async (values) => {
    const {data} = await client.post(`FrequentlyQuestion/AddFrequentlyQuestion`, values)
    return data;
}

export const deleteFrequentlyQuestion = async (id) => {
    const {data} = await client.delete(`FrequentlyQuestion/RemoveFrequentlyQuestion/${id}`);
    return data;
}

export const updateFrequentlyQuestion = async (id, values) => {
    const {data} = await client.patch(`FrequentlyQuestion/EditFrequentlyQuestion/${id}`, values);
    return data;
}


// Article Category

export const articleCategories = async (values) => {
    const {data} = await client.post(`ArticleCategory/ArticleCategoryList`, values);
    return data;
}

export const getAllArticleCategories = async (values) => {
    const {data} = await client.post(`ArticleCategory/GetAllArticleCategoryList`, values)
    return data;
}

export const articleCategory = async (id) => {
    const {data} = await client.get(`ArticleCategory/ArticleCategoryItem/${id}`);
    return data;
}

export const createArticleCategory = async (values) => {
    const {data} = await client.post(`ArticleCategory/AddArticleCategory`, values)
    return data;
}

export const deleteArticleCategory = async (id) => {
    const {data} = await client.delete(`ArticleCategory/RemoveArticleCategory/${id}`);
    return data;
}

export const updateArticleCategory = async (id, values) => {
    const {data} = await client.patch(`ArticleCategory/EditArticleCategory/${id}`, values);
    return data;
}


// Article

export const articles = async (values) => {
    const {data} = await client.post(`Article/ArticleList`, values);
    return data;
}

export const getAllArticles = async (values) => {
    const {data} = await client.post(`Article/GetAllArticleList`, values)
    return data;
}

export const article = async (id) => {
    const {data} = await client.get(`Article/ArticleItem/${id}`);
    return data;
}

export const createArticle = async (values) => {
    const {data} = await client.post(`Article/AddArticle`, values)
    return data;
}

export const deleteArticle = async (id) => {
    const {data} = await client.delete(`Article/RemoveArticle/${id}`);
    return data;
}

export const updateArticle = async (id, values) => {
    const {data} = await client.patch(`Article/EditArticle/${id}`, values);
    return data;
}


// product

export const products = async (values) => {
    const {data} = await client.post(`Product/ProductList`, values);
    return data;
}

export const getAllProducts = async (values) => {
    const {data} = await client.post(`Product/GetAllProductList`, values)
    return data;
}

export const product = async (id) => {
    const {data} = await client.get(`Product/ProductItem/${id}`);
    return data;
}
export const upProduct = async (id) => {
    const {data} = await client.get(`Product/up/${id}`);
    return data;
}
export const downProduct = async (id) => {
    const {data} = await client.get(`Product/down/${id}`);
    return data;
}
export const createProduct = async (values) => {
    const {data} = await client.post(`Product/AddProduct`, values)
    return data;
}

export const deleteProduct = async (id) => {
    const {data} = await client.delete(`Product/RemoveProduct/${id}`);
    return data;
}

export const updateProduct = async (id, values) => {
    const {data} = await client.patch(`Product/EditProduct/${id}`, values);
    return data;
}

export const removeProductImage = async (id) => {
    const {data} = await client.delete(`Product/RemoveProductImage/${id}`)
    return data;
}


// unit

export const units = async (values) => {
    const {data} = await client.post(`Unit/UnitList`, values);
    return data;
}

export const getAllUnits = async (values) => {
    const {data} = await client.post(`Unit/GetAllUnitList`, values)
    return data;
}

export const unit = async (id) => {
    const {data} = await client.get(`Unit/UnitItem/${id}`);
    return data;
}

export const createUnit = async (values) => {
    const {data} = await client.post(`Unit/AddUnit`, values)
    return data;
}

export const deleteUnit = async (id) => {
    const {data} = await client.delete(`Unit/RemoveUnit/${id}`);
    return data;
}

export const updateUnit = async (id, values) => {
    const {data} = await client.patch(`Unit/EditUnit/${id}`, values);
    return data;
}

// banner

export const banners = async (values) => {
    const {data} = await client.post(`Banner/BannerList`, values);
    return data;
}

export const banner = async (id) => {
    const {data} = await client.get(`Banner/BannerItem/${id}`);
    return data;
}

export const createBanner = async (values) => {
    const {data} = await client.post(`Banner/AddBanner`, values)
    return data;
}

export const deleteBanner = async (id) => {
    const {data} = await client.delete(`Banner/RemoveBanner/${id}`);
    return data;
}

export const updateBanner = async (id, values) => {
    const {data} = await client.patch(`Banner/EditBanner/${id}`, values);
    return data;
}

// product slider

export const productSliders = async (values) => {
    const {data} = await client.post(`ProductSlider/ProductSliderList`, values);
    return data;
}

export const productSlider = async (id) => {
    const {data} = await client.get(`ProductSlider/ProductSliderItem/${id}`);
    return data;
}

export const createProductSlider = async (values) => {
    const {data} = await client.post(`ProductSlider/AddProductSlider`, values)
    return data;
}

export const deleteProductSlider = async (id) => {
    const {data} = await client.delete(`ProductSlider/RemoveProductSlider/${id}`);
    return data;
}

export const updateProductSlider = async (id, values) => {
    const {data} = await client.patch(`ProductSlider/EditProductSlider/${id}`, values);
    return data;
}

//  send prices
export const sendPrices = async (values) => {
    const {data} = await client.post(`SendPrice/SendPriceList`, values);
    return data;
}

export const sendPrice = async (id) => {
    const {data} = await client.get(`SendPrice/SendPriceItem/${id}`);
    return data;
}


export const createSendPrice = async (values) => {
    const {data} = await client.post(`SendPrice/AddSendPrice`, values);
    return data;
}


export const deleteSendPrice = async (id) => {
    const {data} = await client.delete(`SendPrice/RemoveSendPrice/${id}`);
    return data;
}


export const updateSendPrice = async (id, values) => {
    const {data} = await client.patch(`SendPrice/EditSendPrice/${id}`, values);
    return data;
}

export const getProvinceWithCity = async () => {
    const {data} = await client.get(`SendPrice/ProvinceWithCityList`);
    return data;
}


//  send prices
export const sendCompanies = async (values) => {
    const {data} = await client.post(`SendCompany/SendCompanyList`, values);
    return data;
}

export const sendCompany = async (id) => {
    const {data} = await client.get(`SendCompany/SendCompanyItem/${id}`);
    return data;
}


export const createSendCompany = async (values) => {
    const {data} = await client.post(`SendCompany/AddSendCompany`, values);
    return data;
}


export const deleteSendCompany = async (id) => {
    const {data} = await client.delete(`SendCompany/RemoveSendCompany/${id}`);
    return data;
}


export const updateSendCompany = async (id, values) => {
    const {data} = await client.patch(`SendCompany/EditSendCompany/${id}`, values);
    return data;
}


//  payment types
export const paymentTypes = async (values) => {
    const {data} = await client.post(`PaymentType/PaymentTypeList`, values);
    return data;
}

export const paymentType = async (id) => {
    const {data} = await client.get(`PaymentType/PaymentTypeItem/${id}`);
    return data;
}


export const createPaymentType = async (values) => {
    const {data} = await client.post(`PaymentType/AddPaymentType`, values);
    return data;
}


export const deletePaymentType = async (id) => {
    const {data} = await client.delete(`PaymentType/RemovePaymentType/${id}`);
    return data;
}


export const updatePaymentType = async (id, values) => {
    const {data} = await client.patch(`PaymentType/EditPaymentType/${id}`, values);
    return data;
}


//  discount
export const discounts = async (values) => {
    const {data} = await client.post(`Discount/DiscountList`, values);
    return data;
}

export const discount = async (id) => {
    const {data} = await client.get(`Discount/DiscountItem/${id}`);
    return data;
}

export const createDiscount = async (values) => {
    const {data} = await client.post(`Discount/AddDiscount`, values);
    return data;
}

export const deleteDiscount = async (id) => {
    const {data} = await client.delete(`Discount/RemoveDiscount/${id}`);
    return data;
}

export const updateDiscount = async (id, values) => {
    const {data} = await client.patch(`Discount/EditDiscount/${id}`, values);
    return data;
}

export const getProductCategories = async (values) => {
    const {data} = await client.post(`Discount/GetNameIdProductCategoryList`, values);
    return data;
}

export const getProducts = async (values) => {
    const {data} = await client.post(`Discount/GetLimitProductList`, values);
    return data;
}

export const getUsers = async (values) => {
    const {data} = await client.post(`Discount/LimitUserList`, values);
    return data;
}


//  comments
export const comments = async (values) => {
    const {data} = await client.post(`Comment/CommentList`, values);
    return data;
}

export const comment = async (id) => {
    const {data} = await client.get(`Comment/CommentItem/${id}`);
    return data;
}

export const deleteComment = async (id) => {
    const {data} = await client.delete(`Comment/RemoveComment/${id}`);
    return data;
}

export const updateComment = async (id, values) => {
    const {data} = await client.patch(`Comment/EditComment/${id}`, values);
    return data;
}



//  orders
export const orders = async (values) => {
    const {data} = await client.post(`Basket/BasketList`, values);
    return data;
}

export const order = async (id) => {
    const {data} = await client.get(`Basket/BasketItem/${id}`);
    return data;
}

export const createOrder = async (values) => {
    const {data} = await client.post(`Basket/AddBasket`, values);
    return data;
}

export const deleteOrder = async (id) => {
    const {data} = await client.delete(`Basket/RemoveBasket/${id}`);
    return data;
}

export const updateOrder = async (id, values) => {
    const {data} = await client.patch(`Basket/EditBasket/${id}`, values);
    return data;
}

export const updateOrderStatus = async (values) => {
    const {data} = await client.patch(`Basket/UpdateBasketStatus`, values);
    return data;
}

export const frontEndProductCategory = async () => {
    client.defaults.headers.common['Authorization'] = ``;
    const {data} = await client.get(`Public/GetProductCategory`);
    return data;
}
export const frontEndProductCategoryWihProduct = async (id) => {
    client.defaults.headers.common['Authorization'] = ``;
    const {data} = await client.get(`Public/GetProductCategoryWihProduct/${id}`);
    return data;
}