import {activeLink} from "../../utils/activeLink";
import {Link, useLocation} from "react-router-dom";

export default function SumMenuItem({subItem}) {

    const route = useLocation();

    return (
        <li className={`menu-item ${activeLink(2,subItem?.name, route.pathname) ? "active open" : ""}`}>
            {
                subItem?.link && subItem?.link !== "" ? (
                    <Link to={`/${subItem?.link || ""}`} className={`menu-link ${subItem?.items && subItem?.items.length > 0 ? "menu-toggle" : ""}`}>
                        <div data-i18n="Basic Inputs">{subItem?.title}</div>
                    </Link>
                ) : (
                    <button  className={`menu-link w-100 border-0 ${subItem?.items && subItem?.items.length > 0 ? "menu-toggle" : ""}`}>
                        <div data-i18n="Form Elements">{subItem?.title}</div>
                    </button>
                )
            }

            {
                subItem?.items && subItem?.items.length > 0 && (
                    <ul className="menu-sub">
                        {
                            subItem?.items.map(item => {
                                return (
                                    <SumMenuItem subItem={item} key={item.id} />
                                )
                            })
                        }
                    </ul>
                )
            }
        </li>
    )
}